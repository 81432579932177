@font-face {
    font-family: "Mulish";
    src: url("fonts/Mulish/Mulish-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Mulish";
    src: url("fonts/Mulish/Mulish-BlackItalic.ttf") format("truetype");
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: "Mulish";
    src: url("fonts/Mulish/Mulish-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Mulish";
    src: url("fonts/Mulish/Mulish-BoldItalic.ttf") format("truetype");
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: "Mulish";
    src: url("fonts/Mulish/Mulish-ExtraBold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "Mulish";
    src: url("fonts/Mulish/Mulish-ExtraBoldItalic.ttf") format("truetype");
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: "Mulish";
    src: url("fonts/Mulish/Mulish-ExtraLight.ttf") format("truetype");
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: "Mulish";
    src: url("fonts/Mulish/Mulish-ExtraLightItalic.ttf") format("truetype");
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: "Mulish";
    src: url("fonts/Mulish/Mulish-Italic.ttf") format("truetype");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "Mulish";
    src: url("fonts/Mulish/Mulish-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Mulish";
    src: url("fonts/Mulish/Mulish-LightItalic.ttf") format("truetype");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "Mulish";
    src: url("fonts/Mulish/Mulish-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Mulish";
    src: url("fonts/Mulish/Mulish-MediumItalic.ttf") format("truetype");
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: "Mulish";
    src: url("fonts/Mulish/Mulish-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Mulish";
    src: url("fonts/Mulish/Mulish-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Mulish";
    src: url("fonts/Mulish/Mulish-SemiBoldItalic.ttf") format("truetype");
    font-weight: 600;
    font-style: italic;
}

html, body {
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
    width: 100vw;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f0f8ff;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

tbody tr:nth-child(2n) {
    background-color: rgba(249, 249, 249, 0.94);
}

form {
    margin-top: 16px;
    margin-bottom: 16px;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #FFF;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #436379;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #143D58;
}
